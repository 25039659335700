import * as React from "react";
import { memo } from "react";
const SvgPasswordGray = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement("g", { clipPath: "url(#145fc2f0-71d7-4006-adac-ee8b959b3fad-a)" }, /* @__PURE__ */ React.createElement(
    "path",
    {
      stroke: "#333",
      strokeLinecap: "round",
      strokeWidth: 2,
      d: "M12 22a10 10 0 1 0 0-20 10 10 0 0 0 0 20Z"
    }
  ), /* @__PURE__ */ React.createElement(
    "path",
    {
      fill: "#333",
      stroke: "#333",
      d: "M13.7 9.81a1.69 1.69 0 1 1-3.39 0 1.69 1.69 0 0 1 3.38 0Z"
    }
  ), /* @__PURE__ */ React.createElement("path", { fill: "#333", d: "M12 9.08v7.29z" }), /* @__PURE__ */ React.createElement(
    "path",
    {
      stroke: "#333",
      strokeLinecap: "round",
      strokeWidth: 2,
      d: "M12 9.08v7.29"
    }
  )),
  /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "145fc2f0-71d7-4006-adac-ee8b959b3fad-a" }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M1 1h22v22H1z" })))
);
const Memo = memo(SvgPasswordGray);
export default Memo;
