import * as React from "react";
import { memo } from "react";
const SvgArrowDown = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "mask",
    {
      id: "6a03ae7a-3d11-4fd4-86ce-e9fb03989a32-a",
      width: 24,
      height: 24,
      x: 0,
      y: 0,
      maskUnits: "userSpaceOnUse",
      style: {
        maskType: "alpha"
      }
    },
    /* @__PURE__ */ React.createElement("path", { fill: "currentColor", d: "M0 24V0h24v24z" })
  ),
  /* @__PURE__ */ React.createElement("g", { mask: "url(#6a03ae7a-3d11-4fd4-86ce-e9fb03989a32-a)" }, /* @__PURE__ */ React.createElement(
    "path",
    {
      fill: "currentColor",
      fillRule: "evenodd",
      d: "M11.66 16.02a1 1 0 0 0 1.41 0l4.95-4.95a1 1 0 0 0-1.41-1.41l-4.25 4.24-4.24-4.24a1 1 0 0 0-1.41 1.41z",
      clipRule: "evenodd"
    }
  ))
);
const Memo = memo(SvgArrowDown);
export default Memo;
